<template>
  <div class="home">
    <div class="header">
      <div>
        <img :src="require('@/assets/images/web/officialAccount.png')" alt="" />
        <h1>长按二维码</h1>
        <p>关注指云端公众号</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="less" scoped>
.home {
  height: 100%;
  .header {
    height: 422px;
    background: linear-gradient(194deg, #0b1a35, #0f466c);
    margin: auto;
    div {
      width: 334px;
      height: 334px;
      text-align: center;
      box-shadow: 0px 2px 16px 0px rgba(11, 27, 54, 0.15);
      padding-top: 240px;
      margin: auto;
      img {
        width: 334px;
        height: 334px;
      }
      h1 {
        font-size: 38px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #0b1a35;
        line-height: 77px;
      }
      p {
        font-size: 32px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #454246;
      }
    }
  }
}
</style>
